import React from "react"
import { Link } from "gatsby"
import IndexPage from "../components/index-page";
import Seo from "../components/seo";

import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { TitleBar } from "../components/titlebar";

const ServicesPage = ({ pageContext , location}) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const blurb = (<React.Fragment><p class="lead">No two engagements are the same, but our typical engagements are some of the following.</p>
    <p className="lead">Please <Link to="/contact">get in touch</Link>, to discuss working together.</p></React.Fragment>)

const title = "Our Services"
const slug = "/services"

return (<React.Fragment>
  <Header containerClasses="container" />
  <Seo title={title} pathname={slug} description={title} />
  <TitleBar title={title} location={location} crumbs={pageContext.breadcrumb}/>
  <IndexPage title={title} startPath="/services/" blurb={blurb} location={location}/>
  <Footer containerClasses="container" />

</React.Fragment>)

}
// Step 3: Export your component
export default ServicesPage